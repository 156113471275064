
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.App-button {
  border: 0;
  display: block;
  width: 100%;
  background: rgb(97, 139, 224);
  color: white;
  font-size: 1.2em;
  padding: 0.5em;
}

.App-viewer {
  position: relative;
  flex: 1;
}

.sheet-button-group {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa; /* Adjust the background color as needed */
  padding: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
}

.sheet-button-group .btn {
  margin: 0 5px; /* Adjust margin between buttons */
}

@media print {
  .frame-bl {
    display: none !important;
  }
}